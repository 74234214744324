import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ContentPageHeader from "../components/ContentPageHeader"
import DependentDropDownToolForm from "../pages/tools/dependent-drop-downs/codeForm";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const InstructionsTemplate = ({ title, content, contentComponent }) => {
    const Instructions = contentComponent || Content;
    
    return (
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="section">
                <Instructions className="content content-instructions" content={content} />
              </div>
            </div>
          </div>
        </div>
    );
};
  
InstructionsTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
};

const DependentDropDownToolPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
          <ContentPageHeader title="Dependent Drop Downs Tool" />
            <InstructionsTemplate
              contentComponent={HTMLContent}
              title={post.frontmatter.title}
              content={post.html}
            />
            <DependentDropDownToolForm />
        </Layout>
    )
}
    
DependentDropDownToolPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default DependentDropDownToolPage

export const instructionsQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

import * as React from "react";
import DependentDropDownCodeTemplate from './codeTemplate'
import CodePreview from "../../../components/codePreview";
import SidebarAd from "../../../components/adUnits/sidebarAd";

function DependentDropDownToolForm() {
    const [code, setCode] = React.useState(0);
    const [targetSheetName, setTargetSheetName] = React.useState('Sheet1')
    const [mappingSheetName, setMappingSheetName] = React.useState('Mappings')
    const [startingColumn, setStartingColumn] = React.useState('A')

    
    React.useEffect(() => {
        // Update the document title using the browser API
        setCode( (code) => code = DependentDropDownCodeTemplate(targetSheetName, mappingSheetName, startingColumn))
    });
    return (
        <div className="container">
            <hr />
            <div className="columns is-multiline">
                <div className="column is-8">
                    <div className="columns is-multiline">
                        <div className="column is-4">
                            <div className="field">
                                <label className="label">Target Sheet Name</label>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text" 
                                        name="sheetName"
                                        placeholder="Sheet1"
                                        onChange={(e) => setTargetSheetName(e.target.value)}
                                    />
                                </div>
                                <p className="is-size-7" style={{marginTop: "0.5rem"}}>Which Sheet should this run on?</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field">
                                <label className="label">Mapping Data Sheet Name</label>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text" 
                                        name="sheetName"
                                        placeholder="Sheet1"
                                        onChange={(e) => setMappingSheetName(e.target.value)}
                                    />
                                </div>
                                <p className="is-size-7" style={{marginTop: "0.5rem"}}>Which Sheet is your mapping data located?</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field">
                                <label className="label">Starting Column</label>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text" 
                                        name="startingColumn"
                                        placeholder="A"
                                        onChange={(e) => setStartingColumn(e.target.value)}
                                    />
                                </div>
                                <p className="is-size-7" style={{marginTop: "0.5rem"}}>Where is the first dropdown located?</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <SidebarAd />
                </div>
            </div>

            <hr />
            <div className="columns is-multiline mb-5 pb-5">
                <div className="column" id="generatedCodeBlock">
                    <h1 className="code-preview-name">Code.gs</h1>
                    <CodePreview code={code} />
                </div>
            </div>
        </div>
    )
}

export default DependentDropDownToolForm;
function DependentDropDownCodeTemplate(targetSheetName, mappingSheetName, startingColumn) {
    // let optionGroupings = {}
    // for(let item in subGroupsSection) {
    //     let optionGroup = subGroupsSection[item]
    //     optionGroupings[optionGroup.groupName] = {
    //         id: optionGroup.id,
    //         groupName: optionGroup.groupName,
    //         parentGroup: optionGroup.parentGroup,
    //         dropDownOptions: optionGroup.dropDownOptions
    //     }
    // }

    return (
        `function settings() {
    return {
        targetSheet: '${targetSheetName}',
        startingColumn: '${startingColumn}',
        mappingSourceSheet: SpreadsheetApp.getActiveSpreadsheet().getSheetByName('${mappingSheetName}'),
    }
}
    
function initialSetup() {
    console.log('Beginning initialization. If prompted to authorize the script, please follow the steps to do so.')
    organizeMappingOptions()
    let sheet = SpreadsheetApp.getActiveSpreadsheet().getSheetByName(settings().targetSheet)
    let topLevelRange = sheet.getRange(2, convertColumn('number', settings().startingColumn), sheet.getMaxRows(), 1)
    topLevelRange.setDataValidation(SpreadsheetApp.newDataValidation()
                                        .requireValueInList(JSON.parse(ScriptProperties.getProperty('top')), true)
                                        .build())
    let triggers = ScriptApp.getProjectTriggers()
    if (triggers.length == 0) {
        createTrigger('trigger_getOptions')
        createTrigger('trigger_updateProps')
    } else {
        let triggerFunctions = []
        for(let item in triggers) {
        triggerFunctions.push(triggers[item].getHandlerFunction())
        }
        triggerFunctions.includes('trigger_getOptions') ? 
                                    console.log('Trigger for trigger_getOptions already exists') : 
                                    createTrigger('trigger_getOptions')
        triggerFunctions.includes('trigger_updateProps') ? 
                                    console.log('Trigger for trigger_updateProps already exists') : 
                                    createTrigger('trigger_updateProps')
    }
    console.log('Initialization Completed')
}
    
function createTrigger(functionName) {
    ScriptApp.newTrigger(functionName)
    .forSpreadsheet(SpreadsheetApp.getActiveSpreadsheet())
    .onEdit()
    .create()
}
    
function organizeMappingOptions() {
    let mappings = settings().mappingSourceSheet.getDataRange().getValues()
    let mappingObj = {}
    let startingColumn = convertColumn('number', settings().startingColumn)
    mappingObj.startingCol = startingColumn
    mappingObj.mappingsEndCol = mappings[0].length+startingColumn-1
    for(let i = 0; i < mappings[0].length; i++) {
        let topLevel = []
        let subLevels = []
        for(let item in mappings) {
            if(item == 0) { continue }
            topLevel.push(mappings[item][0])
            let subLevel = [mappings[item][i], mappings[item][i+1]]
            subLevels.push(subLevel)
        }
        topLevel = [...new Set(topLevel)]
        subLevels = Array.from([...new Set(subLevels.map(JSON.stringify))], JSON.parse)
        mappingObj.top = JSON.stringify(topLevel)
        mappingObj[startingColumn+i] = JSON.stringify(subLevels)
    }
    ScriptProperties.setProperties(mappingObj)
    return mappingObj
}
    
function setCellState(targetCell, type) {
    if(type == 'Pending') {
        targetCell.setValue('');
        targetCell.setBackground('#d8d8d8')
    } else if (type == 'Done') {
        targetCell.setBackground('#fff')
    }
    SpreadsheetApp.flush()
}
    
function convertColumn(target, value) {
    if(target == 'letter') {
        let temp, letter = ''
        while (value > 0) {
            temp = (value - 1) % 26
            letter = String.fromCharCode(temp + 65) + letter
            value = (value - temp - 1) / 26
        }
        return letter
    } else if (target == 'number') {
        var column = 0, length = value.length;
        for (var i = 0; i < length; i++) {
            column += (value.charCodeAt(i) - 64) * Math.pow(26, length - i - 1);
        }
        return column
    } else {
        return 0
    }
}
    
function trigger_updateProps(e) {
    const sheet = e.source.getActiveSheet()
    if(sheet.getName() === settings().mappingSourceSheet.getName()) {
        organizeMappingOptions()
        console.log('Mapping data was updated - Refreshing Script Properties.')
    } else {
        console.log(sheet.getName(), 'was updated - ', settings().mappingSourceSheet.getName(), 'is the mapping sheet.')
    }
}
    
function trigger_getOptions(e) {
    const sheet = e.source.getActiveSheet()
    const range = e.range
    const row = range.getRow()
    const column = range.getColumn()
    const userInput = sheet.getRange(row,column).getValue()
    if(sheet.getName() === settings().targetSheet && 
        row !== 1 && column >= ScriptProperties.getProperty('startingCol') && 
        column < ScriptProperties.getProperty('mappingsEndCol') && 
        range.getNumColumns() == 1
    ) {
        let options = []
        JSON.parse(ScriptProperties.getProperty(column)).filter(function (row) {
                if(row[0] === userInput) {
                options.push(row[1])
            }
        })
        for(let i = 0; i < range.getNumRows(); i++) {
            let targetCell = sheet.getRange(row+i,column+1)
            setCellState(targetCell, 'Pending')
            const rule = SpreadsheetApp.newDataValidation().requireValueInList(options, true).build()
            targetCell.setDataValidation(rule)
            setCellState(targetCell, 'Done')
            console.log('Updated options in ', targetCell.getA1Notation(), 'to ', options)
        }
    } else {
        console.log('No dropdowns to update.')
    }
}
`
    )
}

export default DependentDropDownCodeTemplate